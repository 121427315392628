import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import {
    Modal,
    Box,
    Checkbox,
    FormControlLabel,
    Pagination,
    Grid,
} from "@mui/material";

import TopNavbar from "app/components/TopNavbar/TopNavbar";
import KeywordSearch from "app/components/KeywordSearch";
import Keyword from "app/components/Keyword";
import DictionaryProvider from "app/services/DictionaryProvider";

import dictionaryIcon from "app/assets/images/dictionary.png";
import successIcon from "app/assets/images/success_icon.png";

import Styles from './Dictionary.module.scss'
import 'react-toastify/dist/ReactToastify.css';
import { Keyword as KeywordType } from "app/types/Keyword";
import { GroupType } from "app/types/GroupType";
import DictionaryTable from "app/components/DictionaryTable/DataTable";
import KeywordCard from "app/components/KeywordCard/KeywordCard";
import SearchBar from "app/components/SearchBar/SearchBar";
import { GroupCard } from "app/components/GroupCard/GroupCard";
import GroupProvider from "app/services/GroupProvider";
import { t } from "i18next";
import useEntityStore from "app/stores/entities.store";

type TableSort = "ASC" | "DESC";
interface IState {
    keywords: KeywordType[],
    newKeywords: KeywordType[],
    removedKeywords: KeywordType[],
    showModal: boolean,
    page: number,
    count: number,
    search: string,
    sorting: TableSort,
    groups: GroupType[];
    showKeywordError: boolean;
    selectedGroup: GroupType;
    keepSelectedGroup: boolean;
}

const trimEveryWord = (text: string) => {
    const words = text.split(" ");
    const trimmedWords = words
      .filter((word) => word.trim() !== "")
      .map((word) => word.trim());
    return trimmedWords.join(" ");
  };
  


class Dictionary extends React.Component<WithTranslation, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            keywords: [],
            groups: [],
            newKeywords: [],
            removedKeywords: [],
            page: 1,
            showModal: false,
            count: 0,
            search: "",
            sorting: 'DESC',
            selectedGroup: null,
            showKeywordError: false,
            keepSelectedGroup: false,
        }
    }

    async componentDidMount(): Promise<void> {
        this.retrieveGroups()        
    }

    CustomToastWithLink = () => (
        <div>
            <p>{this.props.t("removeKeyword.toast.success")}</p>
        </div>
    );

    async handleUpdateKeywords() {
        this.setState({ showModal: true })
    }

    retrieveGroups = async () => {
        const groups: GroupType[] = await GroupProvider.getGroups();
        const noGroup: GroupType = {
            createdAt: new Date().toString(),
            updatedAt: new Date().toString(),
            id: 0,
            name: t("groupCard.noGroup"),
            dictionary: [],
        };
        groups
            .sort((a, b) => {
                if (a.entity_type_id && !b.entity_type_id) {
                    return -1;
                }
                if (!a.entity_type_id && b.entity_type_id) {
                    return 1;
                }
                if (a.entity_type_id && b.entity_type_id) {
                    if (a.entity_type_id < b.entity_type_id) {
                        return -1;
                    }
                    if (a.entity_type_id > b.entity_type_id) {
                        return 1;
                    }
                }
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            })
            .unshift(noGroup);
        this.setState({ groups: groups.map(item => {
            if (item.entity_type_id){
                item.name =  t('group.entityType.' + item.name) 
            }
            return item;
        }) });
        this.retrieveDictionary();
    };

    async retrieveDictionary(changeGroup?: boolean) {
        let page = this.state.page;
        if (changeGroup) {
            page = 1;
        }
        let skip = page === 1 ? 0 : (page - 1) * 20;
        const keywords = await DictionaryProvider.getKeywords(
            skip,
            this.state.sorting,
            this.state.selectedGroup?.id,
            this.state.search !== "" ? this.state.search : null
        );
        let amount = keywords.count / 20;
        amount = amount > 1 ? Math.trunc(amount) + 1 : 1;

        const groups = this.state.groups;
        groups[0].dictionary = (
            await DictionaryProvider.getKeywords(null, this.state.sorting, 0, null)
        ).data;


        this.setState({
            keywords: keywords.data,
            removedKeywords: [],
            newKeywords: [],
            groups: groups,
            showModal: false,
            count: amount,
            page: keywords.data.length === 0 ? page - 1 : page,
        });

    }


    componentDidUpdate(
        prevProps: Readonly<WithTranslation<"translation", undefined>>,
        prevState: Readonly<IState>,
        snapshot?: any
    ): void {
        if (prevState.selectedGroup !== this.state.selectedGroup) {
            this.retrieveDictionary(true);
        }
        if (prevState.page !== this.state.page) {
            this.retrieveDictionary();
        }
        if (prevState.groups > this.state.groups && this.state.keepSelectedGroup) {
            this.setState({ selectedGroup: null });
        }
        if (prevState.search !== this.state.search) {
            this.retrieveDictionary(true);
        }

    }

    handleChangeSort(value: TableSort) {
        this.setState({ sorting: value, page: 1 }, () => {
            this.retrieveDictionary();
        });
    }

    handleRemoveKeyword = async (id: number) => {
        const result = await DictionaryProvider.removeKeywords(id)

        if (result[0] === "valid") {
            toast.success(this.CustomToastWithLink(), { icon: <img src={successIcon} /> });
            useEntityStore.getState().deleteAll();   
            this.retrieveDictionary();
        }
        else {
            toast.error(this.props.t('addVip.toast.error'))
        }
    }
    
    handleAddNewKeyword = async (kw: string, group: number, checked: boolean) => {
        const data = { keyword: { keyword: trimEveryWord(kw) , group_id: group, keyword_list_id: checked ? 1 : 2, update: false } }
        const result = await DictionaryProvider.saveKeywords(data)
        if (result[0] == "nonValid") {
            this.setState({ showKeywordError: true })
            setTimeout(() => {
                this.setState({ showKeywordError: false })
            }, 3000);
        } else {
            this.retrieveGroups();
            useEntityStore.getState().deleteAll();   
            this.setState({ showKeywordError: false })
        }
    }


    async handleConfirmKeywordUpdate() {
        if (this.state.newKeywords.length > 0) {
            //   await DictionaryProvider.saveKeywords(
            //     this.state.newKeywords.map((idx) => idx.keyword)
            //   );
        }

        this.retrieveGroups();
        toast.success(this.props.t("dictionary.toast.success"), {
            bodyClassName: Styles.toast,
        });
    }

    handleRemoveNewKeywords() {
        this.setState({
            newKeywords: [],
            removedKeywords: [],
            keywords: [...this.state.keywords, ...this.state.removedKeywords],
        });
    }

    handlePageChange = (event: any, value: number) => {
        this.setState({ page: value }, () => {
            this.retrieveDictionary();
        });
    };

    handleSetSearch = (query: string) => {
        this.setState({ search: query });
    };

    handleKeepSelectedGroup = (checked: boolean) => {
        this.setState({ keepSelectedGroup: checked });
    }

    handleRenderDictionary() {
        if (
            this.state.keywords.length === 0 &&
            this.state.newKeywords.length === 0 &&
            this.state.removedKeywords.length === 0
        ) {
            return (
                <div className={Styles.keywordList}>
                    <div className={Styles.keywordListHeader}>
                        <span className={Styles.kwlLabel}>
                            {this.props.t("dictionary.keywordList")}
                        </span>
                        <SearchBar
                            isSmall={true}
                            getData={() => {}}
                            search={this.handleSetSearch}
                            height={32}
                            fontSize={14}
                            placeholder={this.props.t("dictionary.searchPlaceholder")}
                        />
                    </div>
                    <div className={Styles.nothing}>
                        <img src={dictionaryIcon} alt="dictionary" />
                        <div className={Styles.noKeywordCreated}>
                            {this.props.t("dictionary.noKeywordCreated")}
                        </div>
                        <div className={Styles.noKeywordCreatedSub}>
                            {this.props.t("dictionary.noKeywordCreatedSub")}
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className={Styles.keywordList}>
                <div className={Styles.keywordListHeader}>
                    <span className={Styles.kwlLabel}>
                        {this.props.t("dictionary.keywordList")}
                    </span>
                    <SearchBar
                        isSmall={true}
                        getData={() => {}}
                        search={this.handleSetSearch}
                        height={32}
                        fontSize={14}
                        placeholder={this.props.t("dictionary.searchPlaceholder")}
                    />
                </div>
                <div className="mt-2 pb-2">
                    <DictionaryTable
                        data={this.state.keywords}
                        deleteRow={this.handleRemoveKeyword}
                        changeSorting={(s: TableSort) => this.handleChangeSort(s)}
                        update={this.retrieveGroups}
                        groups={this.state.groups}
                    />
                    <div className="table-pagination">
                        <Pagination
                            count={this.state.count}
                            page={this.state.page}
                            onChange={this.handlePageChange}
                            variant="outlined"
                            shape="circular"
                        />
                    </div>
                </div>
            </div>
        );
    }

    handleClose() {
        this.setState({ showModal: false });
    }

    handleDictionaryRemovalPromptSpawn() {
        if (localStorage.getItem("dictionary.prompt.delete") === null) {
            localStorage.setItem("dictionary.prompt.delete", "1");
        } else {
            localStorage.removeItem("dictionary.prompt.delete");
        }
    }

    handleDelete() {
        this.setState({ showModal: false });
    }

    handleCheckChanges(): boolean {
        if (
            this.state.removedKeywords.length > 0 ||
            this.state.newKeywords.length > 0
        )
            return false;
        return true;
    }

    selectedGroup = (group: number) => {
        this.setState({
            selectedGroup: this.state.groups.find((g) => g.id === group),
        });
    };

    calculateZoomLevel = () => {
        return window.devicePixelRatio * 100;
    };

    render() {
        return (
            <>
            <TopNavbar />
                <div className={[Styles.dictionary, 'container pt-4 pb-4'].join(' ')}>
                    <h1 className={Styles.title}>{this.props.t("general.dictionary")}</h1>
                    <p>{this.props.t("dictionary.description")}</p>
                </div>
                <div className={[Styles.content, 'pt-4 pb-4'].join(' ')}>
                    <div className="container">
                        <Grid container spacing={3}>
                            <Grid item xs={3} >
                                <KeywordCard
                                    addKeyword={(kw: string, group: number, checked: boolean) => this.handleAddNewKeyword(kw, group, checked)}
                                    groups={this.state.groups}
                                    showKeywordError={this.state.showKeywordError}
                                />
                                <GroupCard
                                    groups={this.state.groups}
                                    getGroups={this.retrieveGroups}
                                    selected={this.selectedGroup}
                                    keepSelectedGroup={this.handleKeepSelectedGroup}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <div className={Styles.list}>
                                    {this.handleRenderDictionary()}
                                </div>
                                <div className={Styles.footer}>
                                    <div className="container">
                                        <button
                                            hidden={this.handleCheckChanges()}
                                            onClick={() => this.handleRemoveNewKeywords()}
                                            className={Styles.secondaryBtn}
                                        >
                                            {this.props.t("dictionary.footer.cancel")}
                                        </button>
                                        <button
                                            hidden={this.handleCheckChanges()}
                                            onClick={() => this.handleUpdateKeywords()}
                                            className={Styles.primaryBtn}
                                        >
                                            {this.props.t("dictionary.footer.saveChanges")}
                                        </button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <ToastContainer autoClose={8000} />
            </>
        );
    }
}

export default withTranslation("")(Dictionary);
